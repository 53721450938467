import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://resource.qiyuyouyun.com/common/logo/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'index',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'news',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>新闻资讯</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            name: 'company',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/eBMIjvTHKqXKJiCnipEP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '公司资讯',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Company News',
                },
              ],
            },
          },
          {
            name: 'industry',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/seHhYPdmfBPnbqYVnBHP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '行业资讯',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Industry News',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'solution',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>解决方案</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            name: 'tian-qu-tang',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/eBMIjvTHKqXKJiCnipEP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '天磲堂®',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Tian qu tang SaaS',
                },
              ],
            },
          },
          {
            name: 'xiao-chuan-ai-data',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/seHhYPdmfBPnbqYVnBHP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '中鱼智数®',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'xiao chuan AI data',
                },
              ],
            },
          },
          {
            name: 'free',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/seHhYPdmfBPnbqYVnBHP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '免费上云咨询',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'free',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'about-us',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            name: 'founding-team',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/eBMIjvTHKqXKJiCnipEP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '创始团队',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Founding team',
                },
              ],
            },
          },
          {
            name: 'organization',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/seHhYPdmfBPnbqYVnBHP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '组织架构',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Organization',
                },
              ],
            },
          },
          {
            name: 'contact-us',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/eBMIjvTHKqXKJiCnipEP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '企业文化',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Company culture',
                },
              ],
            },
          },
          {
            name: 'contact-us',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/seHhYPdmfBPnbqYVnBHP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '荣誉资质',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Qualifications',
                },
              ],
            },
          },
          {
            name: 'development-path',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/eBMIjvTHKqXKJiCnipEP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '发展历程',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Development path',
                },
              ],
            },
          },
          {
            name: 'contact-us',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://gw.alipayobjects.com/zos/rmsportal/seHhYPdmfBPnbqYVnBHP.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '联系我们',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: 'Contact us',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <p>数字化转型</p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content',
          children: (
            <span>
              <p>给业务智慧赋能，数据驱动业务高速发展</p>
            </span>
          ),
        },
        button: { className: 'banner2-button', children: '了解更多' },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式业务接入',
            },
            { name: 'content', children: '支付、结算、核算接入产品效率翻四倍' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式事中风险监控',
            },
            {
              name: 'content',
              children: '在所有需求配置环节事前风险控制和质量控制能力',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式数据运营',
            },
            {
              name: 'content',
              children: '沉淀产品接入效率和运营小二工作效率数据',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '小川游鱼合作伙伴', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>小川游鱼科技合作伙伴生态</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://resource.qiyuyouyun.com/common/logo/yuntikj.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>云梯科技</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://resource.qiyuyouyun.com/common/logo/shuliyoupin.jpg',
          },
          content: {
            children: (
              <span>
                <p>书里有品</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://resource.qiyuyouyun.com/common/logo/zyzs.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>中鱼智数</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://xcyy-resource.oss-cn-shanghai.aliyuncs.com/common/logo/qiyuyouyun.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>起鱼有云</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'http://chat.id126.com/static/media/logo.05d552731c61eb26e0b1.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>中鱼AI机器人</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://resource.qiyuyouyun.com/common/logo/aromv-r791e.svg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>小川游鱼科技</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://resource.qiyuyouyun.com/common/logo/tqt-gx-logo.svg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>天磲堂国学</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://resource.qiyuyouyun.com/common/logo/aromv-r791e.svg',
          },
          content: {
            children: (
              <span>
                <p>小川游鱼科技</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>小川游鱼科技提供专业的服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于阿里云强大的基础资源',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业资源管理' },
          content: {
            className: 'content3-content',
            children:
              '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: {
    className: 'footer1 kkqmpzsyjgu-editor_css',
    playScale: 0.2,
    replay: false,
  },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'logo',
        xs: 24,
        md: 4,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://resource.qiyuyouyun.com/common/logo/xcyy.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>
                    Hangzhou Xiaochuan Youyu Technology Co., Ltd.
                    杭州小川游鱼科技有限公司
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'solution',
        xs: 24,
        md: 4,
        className: 'block',
        title: {
          children: (
            <span>
              <p>解决方案</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'data-ai',
              href: 'http://www.cuzsu.com/',
              children: (
                <span>
                  <p>中鱼智数</p>
                </span>
              ),
            },
            {
              name: 'qyyy',
              href: 'http://www.qiyuyouyun.com/',
              children: (
                <span>
                  <p>起鱼有云</p>
                </span>
              ),
            },
            {
              name: 'chat-ai',
              href: 'http://chat.id126.com',
              children: (
                <span>
                  <p>中鱼AI机器人</p>
                </span>
              ),
              target: '_blank',
            },
            {
              name: 'tian-qu-tang',
              href: '#',
              children: (
                <span>
                  <p>云上架构方案</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'open',
        xs: 24,
        md: 4,
        className: 'block',
        title: {
          children: (
            <span>
              <p>开放平台</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'api-doc',
              href: '#',
              children: (
                <span>
                  <p>API文档</p>
                </span>
              ),
            },
            {
              name: 'self-diagnosis',
              href: '#',
              children: (
                <span>
                  <p>自助诊断</p>
                </span>
              ),
            },
            {
              name: 'sdk',
              href: '#',
              children: (
                <span>
                  <p>SDK和工具</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'service',
        xs: 24,
        md: 4,
        className: 'block',
        title: {
          children: (
            <span>
              <p>客户支持</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'service-policy',
              children: (
                <span>
                  <p>服务政策</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'legal-document',
              children: (
                <span>
                  <p>法律文件</p>
                </span>
              ),
            },
            {
              name: 'help-center',
              href: '#',
              children: (
                <span>
                  <p>帮助中心</p>
                </span>
              ),
              target: '_blank',
            },
            {
              href: '#',
              name: 'work-order-support',
              children: (
                <span>
                  <p>工单支持</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'about-us',
        xs: 24,
        md: 4,
        className: 'block',
        title: {
          children: (
            <span>
              <p>关于我们</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'founding-team',
              children: (
                <span>
                  <p>创始团队</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'organization',
              children: (
                <span>
                  <p>组织架构</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'development-path',
              children: (
                <span>
                  <p>发展历程</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'company-culture',
              children: (
                <span>
                  <p>企业文化</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'qualifications',
              children: (
                <span>
                  <p>荣誉资质</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'contact-us',
              children: (
                <span>
                  <p>联系我们</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'new-media ',
        xs: 24,
        md: 4,
        className: 'block',
        title: {
          children: (
            <span>
              <p>新媒体平台</p>
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            // {
            //   name: 'content0',
            //   children: (
            //     <div>
            //       <img alt="新浪微博" src="" />
            //     </div>
            //   ),
            // },
            {
              name: 'wei-xin',
              children: (
                <div>
                  {/* <p>微信公众号:小川游鱼</p> */}
                  <p><img alt="小川游鱼科技微信公众号" src="https://resource.qiyuyouyun.com/common/mew-media/xcyy-scan-gzh.png" width="290" /></p>
                </div>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <div>
        <div>
          <span>《小川游鱼科技隐私权政策》 《小川游鱼科技客户权益保障承诺书》</span>
        </div>
        <div>
          <span>CopyRight © 2018~2023 小川游鱼科技&nbsp;All Rights Reserved&nbsp;<a href='https://beian.miit.gov.cn/' target='_blank'>浙ICP备2022017516号</a>&nbsp;<img src='https://www.beian.gov.cn/img/ghs.png' /><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016899' target='_blank'>浙公网安备33011002016899号</a></span>
        </div>
      </div>
    ),
  },
};
